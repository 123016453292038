(function() {
    let toast = document.getElementById("toast");

    if(toast) {
        let dismissButton = toast.querySelector(".toast-dismiss");

        dismissButton.addEventListener("click", function () {
            toast.classList.add("close");
        });

        toast.addEventListener("animationend", function() {
            if (toast.classList.contains("close")) {
                toast.style.display = "none";
            }
        });
    }
})();